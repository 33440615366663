import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  fetchGoogleMapsURL: async () => {
    const res = await HTTP.get('/google-maps-url', {
      headers: helper.setHeaders()
    })
    return `${ res.data }&callback=Function.prototype`
  },
  validateAddress: async (data) => {
    const res = await HTTP.get('/google-maps-place', {
      headers: helper.setHeaders(),
      params: data
    })
    return res.data
  }
}
