<template>
  <div v-frag>
    <v-data-table
      v-if='!showSuspendedOpportunities'
      v-model='selected'
      :headers='headers'
      :items='opportunities'
      :loading='loading'
      :search='search'
      checkboxColor='primary'
      class='elevation-1 pa-4'
      item-key='id'
      loading-text='Loading opportunities... Please wait'
      show-expand
      show-select
      single-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class='font-weight-bold'>
            {{ sections.volunteer_opportunities || 'Volunteer Opportunities title' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model='search'
            class='mr-4'
            dense
            hide-details
            label='Search'
            outlined
            prepend-inner-icon='mdi-magnify'
            single-line
          >
          </v-text-field>
          <v-btn
            class='text-capitalize font-weight-bold py-5 px-10'
            color='primary'
            outlined
            @click='openAddOpportunity'
          >
            <v-icon left> mdi-plus</v-icon>
            Create New
          </v-btn>
          <v-dialog
            v-model='openEditDateDialog'
            max-width='1000px'
            scrollable
            transition='dialog-bottom-transition'
          >
            <v-form ref='formDate' v-model='valid'>
              <v-card>
                <v-card-title>
                  <span class='headline font-weight-bold'>Edit Date opportunity</span>
                </v-card-title>
                <v-card-text class='py-2 px-4'>
                  <v-container class='pa-6'>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Address</p>
                        <v-text-field
                          v-model='editedDate.address'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e Joystreet 123'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>City</p>
                        <v-text-field
                          v-model='editedDate.city'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='Austin'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>State</p>
                        <v-text-field
                          v-model='editedDate.state'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='Texas'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Zip Code</p>
                        <v-text-field
                          v-model='editedDate.zipCode'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='15420'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Description</p>
                        <v-textarea
                          v-model='editedDate.description'
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          placeholder='i.e Describe the need of the volunteer opportunity in detail here.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'># of Volunteers Required</p>
                        <v-text-field
                          v-model='editedDate.volunteersRequired'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e 20'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Start Time</p>
                        <vue-timepicker
                          v-model='editedDate.startHour'
                          :minute-interval='15'
                          :rules="[(v) => !!v || 'Field is required']"
                          auto-scroll
                          format='h:mm A'
                          @change='calculateHoursPerVolunteerDays'
                        ></vue-timepicker>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>End Time</p>
                        <vue-timepicker
                          v-model='editedDate.endHour'
                          :minute-interval='15'
                          :rules="[(v) => !!v || 'Field is required']"
                          auto-scroll
                          format='h:mm A'
                          @change='calculateHoursPerVolunteerDays'
                        ></vue-timepicker>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Time Zone</p>
                        <v-select
                          v-model='editedDate.timeZone'
                          :items='timeZones'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='description'
                          item-value='id'
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Hours per Volunteer</p>
                        <v-text-field
                          v-model='editedDate.hours'
                          :rules='hoursPerVolunteerDateRules'
                          background-color='#E6E6E6'
                          dense
                          outlined
                          placeholder='i.e 8'
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class='mt-4' md='6'>
                        <p class='font-weight-bold'>Date</p>
                        <v-menu
                          ref='menuDate'
                          v-model='menuDate'
                          :close-on-content-click='false'
                          :return-value.sync='editedDate.date'
                          min-width='auto'
                          offset-y
                          transition='scale-transition'
                        >
                          <template v-slot:activator='{ on, attrs }'>
                            <v-text-field
                              v-model='editedDate.date'
                              :rules="[(v) => !!v || 'Field is required']"
                              dense
                              outlined
                              prepend-inner-icon='mdi-calendar'
                              readonly
                              v-bind='attrs'
                              v-on='on'
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model='editedDate.date'
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn color='primary' text @click='menu = false'>
                              Cancel
                            </v-btn>
                            <v-btn
                              color='primary'
                              text
                              @click='
                                $refs.menuDate.save(editedDate.date)
                              '
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <h3 class='font-weight-bold mt-5 mb-3'>Day of Service Details</h3>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Contact Name</p>
                        <v-text-field
                          v-model='editedDate.contactName'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e John'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Contact Phone</p>
                        <v-text-field
                          v-model='editedDate.contactPhone'
                          :rules="phoneRules"
                          dense
                          outlined
                          placeholder='214-985-5683'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Contact Email</p>
                        <v-text-field
                          v-model='editedDate.contactEmail'
                          dense
                          outlined
                          :rules="[(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
                          placeholder='email@email.com'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Volunteer Special Attire or Preparation</p>
                        <v-textarea
                          v-model='editedDate.specialAttire'
                          outlined
                          placeholder='i.e Describe Volunteer Special Attire or Preparation.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Parking/Building Entry Directions</p>
                        <v-textarea
                          v-model='editedDate.entryInstructions'
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          placeholder='i.e Describe Parking/Building Entry Directions.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Other Important Details</p>
                        <v-textarea
                          v-model='editedDate.otherDetails'
                          outlined
                          placeholder='i.e Describe Other Important Details.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class='d-flex direction-row justify-center'>
                  <v-btn
                    class='px-14 ma-4 font-weight-bold'
                    color='primary'
                    large
                    outlined
                    @click='closeDate'
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled='!valid'
                    class='px-14 ma-4 font-weight-bold white--text'
                    color='primary'
                    large
                    @click='saveDate()'
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog
            v-model='dialog'
            max-width='1000px'
            scrollable
            transition='dialog-bottom-transition'
          >
            <v-form ref='form' v-model='valid'>
              <v-card>
                <v-card-title>
                  <span class='headline font-weight-bold'>{{ formTitle }}</span>
                </v-card-title>
                <v-card-text class='py-2 px-4'>
                  <v-container class='pa-6'>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Need</p>
                        <v-text-field
                          v-model='editedItem.need'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e Food Drive'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Company</p>
                        <v-select
                          v-model='editedItem.member'
                          :items='members'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='name'
                          item-value='id'
                          label='Select a company'
                          outlined
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Address</p>
                        <v-text-field
                          v-model='editedItem.address'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e Joystreet 123'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>City</p>
                        <v-text-field
                          v-model='editedItem.city'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='Austin'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>State</p>
                        <v-text-field
                          v-model='editedItem.state'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='Texas'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Zip Code</p>
                        <v-text-field
                          v-model='editedItem.zipCode'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='15420'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Description</p>
                        <v-textarea
                          v-model='editedItem.description'
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          placeholder='i.e Describe the need of the volunteer opportunity in detail here.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'># of Volunteers Needed</p>
                        <v-text-field
                          v-model='editedItem.spots'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e 20'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Start Time</p>
                        <vue-timepicker
                          v-model='editedItem.startHour'
                          :minute-interval='15'
                          :rules="[(v) => !!v || 'Field is required']"
                          auto-scroll
                          format='h:mm A'
                          @change='calculateHoursPerVolunteer'
                        ></vue-timepicker>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>End Time</p>
                        <vue-timepicker
                          v-model='editedItem.endHour'
                          :minute-interval='15'
                          :rules="[(v) => !!v || 'Field is required']"
                          auto-scroll
                          format='h:mm A'
                          @change='calculateHoursPerVolunteer'
                        ></vue-timepicker>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Time Zone</p>
                        <v-select
                          v-model='editedItem.timeZone'
                          :items='timeZones'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='description'
                          item-value='id'
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Hours per Volunteer</p>
                        <v-text-field
                          v-model='editedItem.hoursPerVolunteer'
                          :rules='hoursPerVolunteerRules'
                          background-color='#E6E6E6'
                          dense
                          outlined
                          placeholder='i.e 8'
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class='mt-4' md='6'>
                        <p class='font-weight-bold'>Start Date</p>
                        <v-menu
                          ref='menuStartDate'
                          v-model='menuStartDate'
                          :close-on-content-click='false'
                          :return-value.sync='editedItem.start'
                          min-width='auto'
                          offset-y
                          transition='scale-transition'
                        >
                          <template v-slot:activator='{ on, attrs }'>
                            <v-text-field
                              v-model='editedItem.start'
                              :rules="[(v) => !!v || 'Field is required']"
                              dense
                              outlined
                              prepend-inner-icon='mdi-calendar'
                              readonly
                              v-bind='attrs'
                              v-on='on'
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model='editedItem.start'
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn color='primary' text @click='menu = false'>
                              Cancel
                            </v-btn>
                            <v-btn
                              color='primary'
                              text
                              @click='
                                $refs.menuStartDate.save(editedItem.start)
                              '
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class='d-flex flex-row align-center px-4'>
                      <h3 class='font-weight-bold pa-0 ma-0'>
                        Require background check?
                      </h3>
                      <v-switch
                        v-model='editedItem.needAuth'
                        class='mx-4 mt-4'
                      ></v-switch>
                    </v-row>
                    <v-row class='d-flex flex-row align-center px-4 mb-10'>
                      <h3 class='font-weight-bold pa-0 ma-0'>
                        Is this a recurring volunteering opportunity?
                      </h3>
                      <v-switch
                        v-model='editedItem.recurrent'
                        class='mx-4 mt-4'
                        @change='onRecurrentSwitch(editedItem.recurrent)'
                      ></v-switch>
                    </v-row>
                    <v-container v-if='editedItem.recurrent' class='pa-0 ma-0'>
                      <v-row class='px-6 mt-4'>
                        <v-row>
                          <p class='font-weight-bold'>
                            Select the volunteering's corresponding days of the
                            week
                          </p>
                        </v-row>
                        <v-row
                          class='
                            d-flex
                            flex-row
                            justify-space-between
                            px-4
                            mt-n4
                          '
                        >
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Monday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Monday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Tuesday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Tuesday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Wednesday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Wednesday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Thursday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Thursday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Friday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Friday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Saturday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Saturday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            :rules='daySelectedRules'
                            hide-details
                            value='Sunday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Sunday</p>
                            </template>
                          </v-checkbox>
                        </v-row>
                      </v-row>
                      <v-row class='px-4 mt-4 d-flex direction-row'>
                        <v-col md='6'>
                          <p class='font-weight-bold'>
                            Select the volunteering's recurrence.
                          </p>
                          <v-row
                            class='
                              px-4
                              mt-4
                              d-flex
                              flex-row
                              align-center
                              justify-start
                            '
                          >
                            <v-radio-group
                              v-model='editedItem.frequency'
                              :rules="[(v) => !!v || 'Field is required']"
                              row
                            >
                              <v-radio label='Weekly' value='Weekly'></v-radio>
                              <v-radio
                                label='Biweekly'
                                value='Biweekly'
                              ></v-radio>
                              <!-- <v-radio
                                label="Once a month"
                                value="Monthly"
                              ></v-radio> -->
                            </v-radio-group>
                          </v-row>
                        </v-col>
                        <v-col md='6'>
                          <p class='font-weight-bold'>End Date</p>
                          <v-menu
                            ref='menuEndDate'
                            v-model='menuEndDate'
                            :close-on-content-click='false'
                            :return-value.sync='editedItem.end'
                            min-width='auto'
                            offset-y
                            transition='scale-transition'
                          >
                            <template v-slot:activator='{ on, attrs }'>
                              <v-text-field
                                v-model='editedItem.end'
                                :rules='[validateEndDate]'
                                dense
                                outlined
                                prepend-inner-icon='mdi-calendar'
                                readonly
                                v-bind='attrs'
                                @change='validateEndDate()'
                                v-on='on'
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model='editedItem.end'
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn color='primary' text @click='menu = false'>
                                Cancel
                              </v-btn>
                              <v-btn
                                color='primary'
                                text
                                @click='$refs.menuEndDate.save(editedItem.end)'
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                    <h3 class='font-weight-bold mt-5 mb-3'>Day of Service Details</h3>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Contact Name</p>
                        <v-text-field
                          v-model='editedItem.contactName'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e John'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Contact Phone</p>
                        <v-text-field
                          v-model='editedItem.contactPhone'
                          :rules="phoneRules"
                          dense
                          outlined
                          placeholder='214-985-5683'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Contact Email</p>
                        <v-text-field
                          v-model='editedItem.contactEmail'
                          dense
                          outlined
                          :rules="[(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
                          placeholder='email@email.com'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Volunteer Special Attire or Preparation</p>
                        <v-textarea
                          v-model='editedItem.specialAttire'
                          outlined
                          placeholder='i.e Describe Volunteer Special Attire or Preparation.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Parking/Building Entry Directions</p>
                        <v-textarea
                          v-model='editedItem.entryInstructions'
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          placeholder='i.e Describe Parking/Building Entry Directions.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Other Important Details</p>
                        <v-textarea
                          v-model='editedItem.otherDetails'
                          outlined
                          placeholder='i.e Describe Other Important Details.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class='d-flex direction-row justify-center'>
                  <v-btn
                    class='px-14 ma-4 font-weight-bold'
                    color='primary'
                    large
                    outlined
                    @click='close'
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled='!valid'
                    class='px-14 ma-4 font-weight-bold white--text'
                    color='primary'
                    large
                    @click='validateAddress'
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model='dialogSuspend' max-width='500px'>
            <v-card class='d-flex flex-column align-start pa-2'>
              <v-card-title class='text-center mb-5'>
                <v-icon
                  class='mr-4 white--text' color='warning' large left
                >mdi-alert
                </v-icon
                >
                Suspend Opporunity
              </v-card-title>
              <v-card-subtitle>
                <b
                  v-if='singleSuspend'
                >You are about to suspend the following opportunity:</b
                >
                <b
                  v-if='!singleSuspend'
                >You are about to suspend the following opportunities:</b
                >
              </v-card-subtitle>
              <p
                v-if='singleSuspend'
                class='ml-8 py-4 font-weight-bold secondary--text'
              >
                {{ editedItem.need }}
              </p>
              <div v-if='!singleSuspend'>
                <p
                  v-for='opportunity in selected'
                  v-bind:key='opportunity.need'
                  class='ml-8 font-weight-bold secondary--text'
                >
                  {{ opportunity.need }}
                </p>
              </div>
              <v-card-actions class='align-self-center'>
                <v-spacer></v-spacer>
                <v-btn
                  class='px-14 mx-4 font-weight-bold text-capitalize'
                  color='primary'
                  large
                  outlined
                  @click='closeSuspend'
                >
                  Cancel
                </v-btn
                >
                <v-btn
                  class='
                    px-14
                    mx-4
                    font-weight-bold
                    text-capitalize
                    white--text
                  '
                  color='warning'
                  large
                  @click='suspendOpportunityConfirm'
                >
                  Suspend
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row
          v-if='selected.length > 0'
          class='d-flex justify-start align-center ml-4 my-4'
        >
          <!-- <v-btn
            outlined
            medium
            class="error--text mr-4"
            @click="onDeleteBtnClick"
          >
            <v-icon left>mdi-close-thick</v-icon>
            Delete
          </v-btn> -->
          <v-btn
            class='warning--text'
            medium
            outlined
            @click='onSuspendBtnClick'
          >
            <v-icon left>mdi-timer-off</v-icon>
            Suspend
          </v-btn>
        </v-row>
        <!-- edit/create alert -->
        <v-alert
          v-model='showSuccessAlert'
          class='rounded-xl'
          color='success'
          dark
          dense
          dismissible
          icon='mdi-check-circle-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3 class='mt-2'>Opportunity modified succesfully!</h3>
          <p>The task has been completed without issues.</p>
        </v-alert>
        <!-- suspend alert -->
        <v-alert
          v-model='showSuspendAlert'
          class='rounded-xl'
          color='warning'
          dark
          dense
          dismissible
          icon='mdi-information-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3 class='mt-2'>Opportunity Suspended</h3>
          <p>
            You can always incorporate them back going to
            <b>"Suspended Opportunities"</b> at the bottom of this page
          </p>
        </v-alert>
      </template>
      <template v-slot:[`item.backgroundCheck`]='{ item }'>
        <span>
          <p
            v-show="item.backgroundCheck == 'no'"
            class='pt-4 font-weight-bold text-start error--text text-capitalize'
          >
            {{ item.backgroundCheck }}
          </p>
          <p
            v-show="item.backgroundCheck == 'yes'"
            class='pt-4 font-weight-bold text-start success--text text-capitalize'
          >
            {{ item.backgroundCheck }}
          </p>
        </span>
      </template>
      <template v-slot:[`item.need`]='{ item }'>
        <span @click='onNeedClick(item)'>
          <p class='text-decoration-underline mt-4'>
            {{ item.need }}
          </p>
        </span>
      </template>
      <template v-slot:[`item.time`]='{ item }'>
        <span
        >{{ item.startHour }} - {{ item.endHour }}
          <span v-if="item.timeZone !== ''"> ({{ item.timeZone }}) </span>
        </span>
      </template>
      <template v-slot:[`item.member`]='{ item }'>
        <span v-if='allSelected'>{{ item.name }}</span>
      </template>

      <template v-slot:[`item.actions`]='{ item }'>
        <v-icon
          class='mr-2 my-1 pa-2 primary white--text rounded-sm'
          dense
          @click='editItem(item)'
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class='mr-2 my-1 pa-2 warning white--text rounded-sm'
          dense
          @click='suspendOpportunity(item)'
        >
          mdi-timer-off
        </v-icon>
        <!-- <v-icon
          dense
          class="mr-2 my-1 pa-2 error white--text rounded-sm"
          @click="deleteItem(item)"
        >
          mdi-close-thick
        </v-icon> -->
      </template>
      <template v-slot:no-data>
        <span>There are no suspended opportunities at the moment.</span>
      </template>
      <template v-slot:expanded-item='{ headers, item }'>
        <td :colspan='headers.length' :style="{padding: 0+'px'}">
          <v-data-table
            :headers='headersEx'
            :hide-default-footer='true'
            :items='item.date'
            disable-pagination
          >
            <template v-slot:[`item.time`]='{ item }'>
              <span
              >{{ item.startHour }} - {{ item.endHour }}
                <span v-if="item.timeZone !== ''"> ({{ item.timeZone }}) </span>
              </span>
            </template>

            <template v-slot:[`item.actions`]='{ item }'>
              <v-icon
                class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                dense
                @click='openDatesModal(item)'
              >
                mdi-pencil
              </v-icon>
              <v-icon
                class='mr-2 my-1 pa-2 error white--text rounded-sm'
                dense
                @click='deleteDate(item)'
              >
                mdi-close-thick
              </v-icon>
            </template>
            <template v-slot:no-data>
              <span>There are no dates.</span>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <v-data-table
      v-if='showSuspendedOpportunities'
      v-model='suspendedSelected'
      :headers='headers'
      :items='suspendedOpportunities'
      :loading='loadingSuspended'
      :search='search'
      checkboxColor='primary'
      class='elevation-1 pa-4'
      hide-default-footer
      item-key='id'
      loading-text='Loading... Please wait'
      show-select
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            class='font-weight-bold'
          >Suspended Opportunities
          </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model='search'
            class='mr-4'
            dense
            hide-details
            label='Search'
            outlined
            prepend-inner-icon='mdi-magnify'
            single-line
          >
          </v-text-field>
          <template v-slot:[`item.time`]='{ item }'>
            <span>{{ item.startHour }} - {{ item.endHour }}</span>
          </template>
          <template v-slot:[`item.member`]='{ item }'>
            <span>{{ item.name }}</span>
          </template>

          <v-dialog v-model='dialogDelete' max-width='500px'>
            <v-card class='d-flex flex-column align-start pa-2'>
              <v-card-title class='text-center mb-5'>
                <v-icon
                  class='mr-4 white--text' color='error' large left
                >mdi-alert
                </v-icon
                >
                Delete Opportunity
              </v-card-title>
              <v-card-subtitle>
                <b
                  v-if='singleDelete'
                >You are about to delete the following opportunity:</b
                >
                <b
                  v-if='!singleDelete'
                >You are about to delete the following opportunity:</b
                >
              </v-card-subtitle>
              <p
                v-if='singleDelete'
                class='ml-8 py-4 font-weight-bold secondary--text'
              >
                {{ editedItem.need }}
              </p>
              <div v-if='!singleDelete'>
                <p
                  v-for='opportunity in suspendedSelected'
                  v-bind:key='opportunity.need'
                  class='ml-8 font-weight-bold secondary--text'
                >
                  {{ opportunity.need }}
                </p>
              </div>
              <v-card-actions class='align-self-center'>
                <v-spacer></v-spacer>
                <v-btn
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='primary'
                  large
                  outlined
                  @click='closeDelete'
                >
                  Cancel
                </v-btn
                >
                <v-btn
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='primary'
                  large
                  @click='openDeleteConfirmation'
                >
                  Confirm
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model='dialogDeleteConfirmation' max-width='550px'>
            <v-card class='d-flex flex-column align-start pa-2'>
              <v-card-title class='text-center mb-5'>
                <v-icon
                  class='mr-4 white--text' color='error' large left
                >mdi-alert
                </v-icon
                >
                Confirm Delete Opportunity
              </v-card-title>
              <v-card-subtitle>
                <b
                  v-if='singleDelete'
                >Are you sure you want to delete this opportunity permanently?
                  <span
                    class='error--text'
                  >There is no way to undo this action.</span
                  ></b
                >
                <b
                  v-if='!singleDelete'
                >Are you sure you want to delete these opportunities
                  permanently?
                  <span
                    class='error--text'
                  >There is no way to undo this action.</span
                  ></b
                >
              </v-card-subtitle>
              <p
                v-if='singleDelete'
                class='ml-8 py-4 font-weight-bold secondary--text'
              >
                {{ editedItem.need }} will be deleted permanently.
              </p>
              <div v-if='!singleDelete'>
                <p
                  v-for='opportunity in suspendedSelected'
                  v-bind:key='opportunity.need'
                  class='ml-8 font-weight-bold secondary--text'
                >
                  {{ opportunity.need }}
                </p>
              </div>
              <v-card-actions class='align-self-center'>
                <v-spacer></v-spacer>
                <v-btn
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='primary'
                  large
                  outlined
                  @click='closeDeleteConfirmation'
                >
                  Cancel
                </v-btn
                >
                <v-btn
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='error'
                  large
                  @click='deleteItemConfirm'
                >
                  Yes, Delete Now
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model='dialogReincorporate' max-width='500px'>
            <v-card class='d-flex flex-column align-start pa-2'>
              <v-card-title class='text-center mb-5'>
                <v-icon
                  class='mr-4 white--text' color='success' large left
                >mdi-alert
                </v-icon
                >
                Reincorporate Opportunity
              </v-card-title>
              <v-card-subtitle>
                <b
                  v-if='singleReincorporate'
                >You are about to reincorporate the following opportunity:</b
                >
                <b
                  v-if='!singleReincorporate'
                >You are about to reincorporate the following
                  opportunities:</b
                >
              </v-card-subtitle>
              <p
                v-if='singleReincorporate'
                class='ml-8 py-4 font-weight-bold secondary--text'
              >
                {{ editedItem.need }}
              </p>
              <div v-if='!singleReincorporate'>
                <p
                  v-for='opportunity in suspendedSelected'
                  v-bind:key='opportunity.need'
                  class='ml-8 font-weight-bold secondary--text'
                >
                  {{ opportunity.need }}
                </p>
              </div>
              <v-card-actions class='align-self-center'>
                <v-spacer></v-spacer>
                <v-btn
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='primary'
                  large
                  outlined
                  @click='closeReincorporate'
                >
                  Cancel
                </v-btn
                >
                <v-btn
                  class='px-14 mx-4 font-weight-bold white--text'
                  color='primary'
                  large
                  @click='reincorporateOpportunityConfirm'
                >
                  Confirm
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row
          v-if='suspendedSelected.length > 0'
          class='d-flex justify-start align-center ml-4 my-4'
        >
          <v-btn
            class='error--text mr-4'
            medium
            outlined
            @click='onDeleteBtnClick'
          >
            <v-icon left>mdi-close-thick</v-icon>
            Delete
          </v-btn>
          <v-btn
            class='success--text'
            medium
            outlined
            @click='onReincorporateBtnClick'
          >
            <v-icon left>mdi-undo</v-icon>
            Reincorporate
          </v-btn>
        </v-row>
        <!-- delete alert -->
        <v-alert
          v-model='showDeleteAlert'
          class='rounded-xl'
          color='grey'
          dark
          dense
          dismissible
          icon='mdi-information-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3 v-if='singleDelete' class='mt-2'>Opportunity Deleted</h3>
          <p v-if='singleDelete'>
            Selected opportunity was succesfully deleted.
          </p>

          <h3 v-if='!singleDelete' class='mt-2'>Opportunities Deleted</h3>
          <p v-if='!singleDelete'>
            Selected opportunities were succesfully deleted.
          </p>
        </v-alert>

        <!-- reincorporate alert -->
        <v-alert
          v-model='showReincorporateAlert'
          class='rounded-xl'
          color='success'
          dark
          dense
          dismissible
          icon='mdi-information-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3 v-if='singleReincorporate' class='mt-2'>
            Opportunity Reincorporated
          </h3>
          <p v-if='singleReincorporate'>
            Selected opportunity was succesfully reincorporated.
          </p>

          <h3 v-if='!singleReincorporate' class='mt-2'>
            Opportunities Reincorporated
          </h3>
          <p v-if='!singleReincorporate'>
            Selected opportunities were succesfully reincorporated.
          </p>
        </v-alert>
      </template>
      <template v-slot:[`item.backgroundCheck`]='{ item }'>
        <span>
          <p
            v-show="item.backgroundCheck == 'no'"
            class='pt-4 font-weight-bold text-start error--text text-capitalize'
          >
            {{ item.backgroundCheck }}
          </p>
          <p
            v-show="item.backgroundCheck == 'yes'"
            class='pt-4 font-weight-bold text-start success--text text-capitalize'
          >
            {{ item.backgroundCheck }}
          </p>
        </span>
      </template>
      <template v-slot:[`item.time`]='{ item }'>
        <span
        >{{ item.startHour }} - {{ item.endHour }}
          <span v-if="item.timeZone !== ''"> ({{ item.timeZone }}) </span>
        </span>
      </template>
      <template v-slot:[`item.actions`]='{ item }'>
        <v-icon
          class='mr-2 my-1 pa-2 error white--text rounded-sm'
          dense
          @click='deleteItem(item)'
        >
          mdi-close-thick
        </v-icon>
        <v-icon
          class='mr-2 my-1 pa-2 success white--text rounded-sm'
          dense
          @click='reincorporateOpportunity(item)'
        >
          mdi-undo
        </v-icon>
      </template>
      <template v-slot:no-data>
        <span> There are no active opportunities at the moment.</span>
      </template>
    </v-data-table>
    <div
      class='
        text-center
        d-flex
        flex-row
        align-baseline
        justify-end
        mr-10
        pt-4
        pb-8
      '
    >
      <v-btn
        v-if='!showSuspendedOpportunities'
        class='text-capitalize font-weight-bold py-5 ml-4'
        color='warning'
        outlined
        @click='toggleDataTables'
      >
        view all suspended opportunities
      </v-btn>
      <v-btn
        v-if='showSuspendedOpportunities'
        class='text-capitalize font-weight-bold py-5 ml-4'
        color='primary'
        outlined
        @click='toggleDataTables'
      >
        return to active opportunities
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <p class="text-bold">Previous</p>
      <v-pagination :length="5"></v-pagination>
      <p class="text-bold">Next</p> -->
    </div>
    <v-dialog v-model='dialogAddress' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon
            class='mr-4 white--text' color='warning' large left
          >mdi-alert
          </v-icon
          >
          Warning!
        </v-card-title>
        <v-card-subtitle>
          <b
          >The entered address may not belong to a real address, do you want
            to continue with the operation?</b
          >
        </v-card-subtitle>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='dialogAddress = false'
          >
            Cancel
          </v-btn
          >
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='save'
          >
            Confirm
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class='elevation-2 pa-4'>
      <v-card-title class='font-weight-bold'>
        {{ sections.all_volunteers || 'All Volunteers title' }}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-data-table
        v-model='volunteers'
        :headers='headersVolunteer'
        :items='volunteers'
        :loading='loading'
        item-key='id'
        loading-text='Loading volunteers... Please wait'
      >

        <template v-slot:[`item.fullName`]='{ item }'>
          <p>
            {{ item.fullName }}
          </p>
        </template>
        <template v-slot:[`item.email`]='{ item }'>
          <p>
            {{ item.email }}
          </p>
        </template>
        <template v-slot:[`item.phone`]='{ item }'>
          <p>
            {{ item.phone }}
          </p>
        </template>
        <template v-slot:[`item.company`]='{ item }'>
          <p>
            {{ item.company }}
          </p>
        </template>
        <template v-slot:[`item.hours`]='{ item }'>
          <p>
            {{ item.hours }}
          </p>
        </template>
        <template v-slot:[`item.background`]='{ item }'>
          <p>
            {{ item.background }}
          </p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import opportunity from '../../services/opportunities'
import volunteers from '../../services/volunteers'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import googleMapsService from '../../services/googleMaps'
import { mapState } from 'vuex'

export default {
  components: { VueTimepicker },
  data: () => ({
    dialog: false,
    valid: true,
    loading: true,
    loadingSuspended: true,
    dialogDelete: false,
    dialogDeleteConfirmation: false,
    singleDelete: true,
    singleSuspend: true,
    suspend: false,
    reincorporate: false,
    singleReincorporate: true,
    dialogReincorporate: false,
    dialogSuspend: false,
    dialogDates: false,
    dialogAddress: false,
    singleSelect: true,
    time: null,
    menuStartDate: false,
    editedDate: [],
    menuDate: false,
    menuEndDate: false,
    startingTimeMenu: false,
    endingTimeMenu: false,
    search: '',
    showSuccessAlert: false,
    showSuspendAlert: false,
    showDeleteAlert: false,
    showReincorporateAlert: false,
    showSuspendedOpportunities: false,
    selected: [],
    volunteers: [],
    opportunityDates: [],
    suspendedSelected: [],
    headersEx: [
      {
        text: 'Description',
        align: 'start',
        sortable: true,
        value: 'description'
      },
      { text: 'Date', value: 'date', sortable: 'true' },
      {
        text: 'Hs.',
        sortable: true,
        value: 'hours'
      },
      { text: 'Time', value: 'time' },
      { text: 'Address', value: 'address' },
      { text: 'Spots', value: 'volunteersRequired' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    headers: [
      {
        text: 'Background Check',
        align: 'start',
        sortable: false,
        value: 'backgroundCheck'
      },
      {
        text: 'Need',
        align: 'start',
        sortable: true,
        value: 'need'
      },
      {
        text: 'Hs.',
        sortable: true,
        value: 'hoursPerVolunteer'
      },
      { text: 'Time', value: 'time' },
      { text: 'Address', value: 'address' },
      { text: 'Spots', value: 'spots' },
      { text: 'Member Company', value: 'member.name' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    headersVolunteer: [
      {
        text: 'Employee',
        align: 'start',
        value: 'fullName'
      },
      { text: 'Email', value: 'email', sortable: 'true' },
      {
        text: 'Phone',
        value: 'phoneNumber'
      },
      { text: 'Company', value: 'company' },
      { text: 'Hours volunteered', value: 'hours' },
      { text: 'Background check status', value: 'background' }
    ],
    opportunities: [],
    openEditDateDialog: false,
    editDaysOpportunity: [],
    suspendedOpportunities: [],
    members: [],
    timeZones: ['PT', 'MT', 'CT', 'ET', 'CST'],
    editedIndex: -1,
    editedItem: {
      id: '',
      need: '',
      member: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      description: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      specialAttire: '',
      entryInstructions: '',
      otherDetails: '',
      spots: '',
      hoursPerVolunteer: '',
      startHour: '',
      endHour: '',
      timeZone: '',
      start: '',
      end: '',
      recurrent: false,
      needAuth: Boolean,
      dayOfWeek: '',
      frequency: '',
      weekDay: []
    },
    defaultItem: {
      id: '',
      need: '',
      member: '',
      address: '',
      description: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      specialAttire: '',
      entryInstructions: '',
      otherDetails: '',
      spots: '',
      hoursPerVolunteer: '',
      startHour: '',
      endHour: '',
      timeZone: '',
      start: '',
      end: '',
      needAuth: false,
      recurrent: false,
      frequency: '',
      weekDay: []
    },
    phoneRules: [
      v => !!v || "Field is required'",
      v => /^\d+$/.test(v) || "Value must be a number",
      v => v.length <= 10 || "Value must be equal to 10 characters",
      v => v.length >= 10 || "Value must be equal to 10 characters",
    ],
  }),
  computed: {
    ...mapState('cmsStore', ['sections']),
    formTitle() {
      return this.editedIndex === -1
        ? 'New Volunteer Opportunity'
        : 'Edit Volunteer Opportunity'
    },
    hoursPerVolunteerRules() {
      return [
        this.editedItem.hoursPerVolunteer > 0 ||
        'End hour must be after start hour.'
      ]
    },
    hoursPerVolunteerDateRules() {
      return [
        this.editedDate.hours > 0 ||
        'End hour must be after start hour.'
      ]
    },
    daySelectedRules() {
      return [
        this.editedItem.weekDay.length > 0 ||
        'At least one day of the wee should be selected'
      ]
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.getOpportunities()
      this.getSuspendedOpportunities()
      this.getMembers()
      this.getEmployees()
    },
    allowedStep: (m) => m % 5 === 0,
    async getOpportunities() {
      try {
        this.opportunities = await opportunity.getOpportunities()
        this.opportunities.forEach((op) => {
          if (op.member.length) {
            op.member = {
              name: 'All',
              id: 'all'
            }
          }
        })
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async getEmployees() {
      try {
        this.volunteers =
          await volunteers.getAllVolunteers()
      } catch (err) {
        console.log(err)
      }
    },
    async getSuspendedOpportunities() {
      try {
        this.suspendedOpportunities =
          await opportunity.getSuspendedOpportunities()
        this.suspendedOpportunities.forEach((op) => {
          if (op.member.length) {
            op.member = {
              name: 'All',
              id: 'all'
            }
          }
        })
        this.loadingSuspended = false
      } catch (err) {
        console.log(err)
      }
    },
    async getMembers() {
      try {
        this.members = await opportunity.getMembers()
        this.members.unshift({
          name: 'All',
          id: 'all'
        })
        this.loadingSuspended = false
      } catch (err) {
        console.log(err)
      }
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.showSuccessAlert = false
        this.showSuspendAlert = false
        this.showDeleteAlert = false
        this.showReincorporateAlert = false
      }, 6000)
    },

    showAlertMsg(type) {
      switch (type) {
        case 'success':
          this.showSuccessAlert = true
          break
        case 'suspend':
          this.showSuspendAlert = true
          break
        case 'delete':
          this.showDeleteAlert = true
          break
        case 'reincorporate':
          this.showReincorporateAlert = true
          break
      }
      this.hideAlertMsg()
    },
    calculateHoursPerVolunteer() {
      let start = this.editedItem.startHour.valueOf()
      let end = this.editedItem.endHour.valueOf()

      let timeStart = new Date('01/01/2021 ' + start)
      let timeEnd = new Date('01/01/2021 ' + end)

      let hourDiffinMS = timeEnd - timeStart
      let hours = hourDiffinMS / (1000 * 60 * 60)

      if (hours > 0) {
        this.editedItem.hoursPerVolunteer = hours
      }
      if (hours < 0) {
        this.editedItem.hoursPerVolunteer = 0
        this.valid = false
      }
    },
    calculateHoursPerVolunteerDays() {
      let start = this.editedDate.startHour.valueOf()
      let end = this.editedDate.endHour.valueOf()

      let endFull = end.split(' ')
      let startFull = start.split(' ')

      let endHour = +endFull[0].split(':')[0]
      let startHour = +startFull[0].split(':')[0]
      let startMinute = +startFull[0].split(':')[1]
      let endMinute = +endFull[0].split(':')[1]

      if (endFull[1] == 'PM') {
        endHour += 12
      }
      if (startFull[1] == 'PM') {
        startHour += 12
      }

      let hours = endHour - startHour
      let minute = endMinute - startMinute

      if (minute < 0) {
        hours -= 1
      }

      if (hours > 0) {
        this.editedDate.hours = hours
      }
      if (hours < 0) {
        this.editedDate.hours = 0
        this.valid = false
      }

    },
    validateEndDate() {
      const endDate = new Date(this.editedItem.end)
      const startDate = new Date(this.editedItem.start)
      const sevenDays = 604800000
      const dateDiff = endDate - startDate
      if (this.editedItem.end && endDate > startDate && dateDiff >= sevenDays) {
        return true
      }
      if (endDate <= startDate) {
        return 'Ending date must be after Starting Date'
      }
      if (dateDiff < sevenDays) {
        return 'End date must be at least 7 days after start date'
      } else {
        return 'Field is Required'
      }
    },

    openAddOpportunity() {
      this.editedIndex = -1
      this.editedItem.endHour = '08:00 AM'
      this.editedItem.startHour = '08:00 AM'

      this.editedItem.member = 'all'
      this.editedItem.timeZone = 'CST'
      this.dialog = true
    },
    editItem(item) {
      this.editedItem = {}
      this.editedIndex = this.opportunities.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.editedItem.start = this.formatDate(this.editedItem.start)
      this.editedItem.end = this.formatDate(this.editedItem.end)

      this.calculateHoursPerVolunteer()
      this.dialog = true
    },
    onDeleteBtnClick() {
      this.singleDelete = false
      this.dialogDelete = true
    },
    deleteMultipleOpportunities() {
      this.deleteOpportunities()
      this.suspendedSelected.forEach((opportunity) => {
        const index = this.suspendedOpportunities.indexOf(opportunity)
        if (index > -1) this.suspendedOpportunities.splice(index, 1)
      })
      this.suspendedSelected = []
    },
    deleteItem(item) {
      this.singleDelete = true
      this.editedIndex = this.suspendedOpportunities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      this.suspendedSelected = []
    },
    openDeleteConfirmation() {
      this.dialogDelete = false
      this.dialogDeleteConfirmation = true
    },
    deleteItemConfirm() {
      if (this.singleDelete) {
        this.suspendedOpportunities.splice(this.editedIndex, 1)
        this.deleteOpportunity()
      } else {
        this.deleteMultipleOpportunities()
      }
      this.closeDeleteConfirmation()
      this.showAlertMsg('delete')
    },
    closeDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.$refs.form.reset()
      this.dialog = false
    },
    closeDate() {
      this.$refs.formDate.reset()
      this.openEditDateDialog = false
    },
    closeDelete() {
      this.dialogDelete = false
    },
    onReincorporateBtnClick() {
      this.singleReincorporate = false
      this.dialogReincorporate = true
    },
    onSuspendBtnClick() {
      this.singleSuspend = false
      this.dialogSuspend = true
    },
    reincorporateOpportunity(item) {
      this.singleReincorporate = true
      this.editedIndex = this.suspendedOpportunities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogReincorporate = true
      this.suspendedSelected = []
    },
    reincorporateMultipleOpportunities() {
      this.reincorporate = true
      this.enableDisableOpportunities()
    },
    reincorporateOpportunityConfirm() {
      if (this.singleReincorporate) {
        this.enableDisableOpportunity()
      } else {
        this.reincorporateMultipleOpportunities()
      }
      this.getSuspendedOpportunities()
      this.suspendedSelected = []
      this.closeReincorporate()
      this.showAlertMsg('reincorporate')
    },
    closeReincorporate() {
      this.dialogReincorporate = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    suspendMultipleOpportunities() {
      this.suspend = true
      this.enableDisableOpportunities()
    },
    suspendOpportunity(item) {
      this.editedIndex = this.opportunities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSuspend = true
    },
    async deleteDate(item) {
      try {
        await opportunity.deleteDate({
          id: item.id
        })
          .then(el => {
            this.opportunities.find(element => element.id === el.id)
              .date
              .splice(this.opportunities.find(element => element.id === el.id)
                .date
                .indexOf(item), 1)
          })

      } catch (err) {
        console.log(err)
      }
    },
    async saveDate() {
      const item = this.editedDate
      try {
        await opportunity.editDates({
          date: item
        })
      } catch (err) {
        console.log(err)
      }
      this.openEditDateDialog = false
    },
    async createDate() {
      let id = this.editDaysOpportunity.id
      let hours = this.editDaysOpportunity.hoursPerVolunteer
      try {
        await opportunity.createDate({ id, hours })
          .then(el => {
            this.opportunityDates.push({ id: el.id, date: el.date })
          })
      } catch (err) {
        console.log(err)
      }
    },
    suspendOpportunityConfirm() {
      if (this.singleSuspend) {
        this.enableDisableOpportunity()
      } else {
        this.suspendMultipleOpportunities()
      }
      this.getOpportunities()
      this.selected = []
      this.closeSuspend()
      this.showAlertMsg('suspend')
    },
    closeSuspend() {
      this.dialogSuspend = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    toggleDataTables() {
      this.showSuspendedOpportunities = !this.showSuspendedOpportunities
      if (this.showSuspendedOpportunities) {
        this.getSuspendedOpportunities()
      } else {
        this.getOpportunities()
      }
    },

    onRecurrentSwitch(recurrent) {
      if (recurrent) {
        this.editedItem.end = null
      }
    },
    async validateAddress() {
      const data = {
        address: this.editedItem.address,
        state: this.editedItem.state,
        city: this.editedItem.city,
        zip_code: this.editedItem.zipCode,
        country: 'USA'
      }
      const res = await googleMapsService.validateAddress(data)
      if (res.statusMcba) {
        this.save()
      } else {
        this.dialogAddress = true
      }
    },
    async save() {
      this.$refs.form.validate()
      if (this.editedIndex > -1) {
        Object.assign(this.opportunities[this.editedIndex], this.editedItem)
        if (!this.editedItem.recurrent) {
          this.editedItem.weekDay = []
          this.editedItem.frequency = ''
          this.editedItem.end = ''
        }
        if (this.editedItem.member.name == 'All') {
          this.editedItem.member = this.editedItem.member.id
        }
        if (this.editedItem.member.id) {
          this.editedItem.member = this.editedItem.member.id
        }
        await this.editOpportunity()
      } else {
        if (!this.editedItem.recurrent) {
          this.editedItem.weekDay = []
          this.editedItem.frequency = ''
          this.editedItem.end = ''
        }
        this.addOpportunity()
      }
      this.getOpportunities()
      this.dialogAddress = false
      this.close()
      this.showAlertMsg('success')
    },
    async addOpportunity() {
      try {
        await opportunity.addOpportunity({
          need: this.editedItem.need,
          memberId: this.editedItem.member,
          address: this.editedItem.address,
          city: this.editedItem.city,
          state: this.editedItem.state,
          zip_code: this.editedItem.zipCode,
          description: this.editedItem.description,
          contactName: this.editedItem.contactName,
          contactPhone: this.editedItem.contactPhone,
          contactEmail: this.editedItem.contactEmail,
          specialAttire: this.editedItem.specialAttire,
          entryInstructions: this.editedItem.entryInstructions,
          otherDetails: this.editedItem.otherDetails,
          volunteersRequired: this.editedItem.spots,
          hoursPerVolunteer: this.editedItem.hoursPerVolunteer,
          startHour: this.editedItem.startHour,
          endHour: this.editedItem.endHour,
          timeZone: this.editedItem.timeZone,
          start: this.editedItem.start,
          end: this.editedItem.end,
          recurrent: this.editedItem.recurrent,
          weekDay: this.editedItem.weekDay,
          frequency: this.editedItem.frequency,
          needAuth: this.editedItem.needAuth
        })
        this.getOpportunities()
      } catch (err) {
        console.log(err)
      }
    },
    async editOpportunity() {
      try {
        await opportunity.editOpportunity({
          id: this.editedItem.id,
          need: this.editedItem.need,
          memberId: this.editedItem.member,
          address: this.editedItem.address,
          city: this.editedItem.city,
          state: this.editedItem.state,
          zip_code: this.editedItem.zipCode,
          description: this.editedItem.description,
          contactName: this.editedItem.contactName,
          contactPhone: this.editedItem.contactPhone,
          contactEmail: this.editedItem.contactEmail,
          specialAttire: this.editedItem.specialAttire,
          entryInstructions: this.editedItem.entryInstructions,
          otherDetails: this.editedItem.otherDetails,
          volunteersRequired: this.editedItem.spots,
          recurrent: this.editedItem.recurrent,
          hoursPerVolunteer: this.editedItem.hoursPerVolunteer,
          startHour: this.editedItem.startHour,
          endHour: this.editedItem.endHour,
          timeZone: this.editedItem.timeZone,
          start: this.editedItem.start,
          end: this.editedItem.end,
          weekDay: this.editedItem.weekDay,
          frequency: this.editedItem.frequency,
          needAuth: this.editedItem.needAuth
        })
        this.getOpportunities()
      } catch (err) {
        console.log(err)
      }
    },
    async enableDisableOpportunity() {
      try {
        await opportunity.enableDisableOpportunity({
          id: this.editedItem.id
        })
        this.getOpportunities()
      } catch (err) {
        console.log(err)
      }
    },
    async enableDisableOpportunities() {
      try {
        if (this.suspend) {
          await opportunity.enableDisableOpportunities({
            opportunities: this.selected
          })
          this.getOpportunities()
          this.suspend = false
        } else if (this.reincorporate) {
          await opportunity.enableDisableOpportunities({
            opportunities: this.suspendedSelected
          })
          this.getSuspendedOpportunities()
          this.reincorporate = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async deleteOpportunity() {
      try {
        await opportunity.deleteOpportunity({
          id: this.editedItem.id
        })
        this.getSuspendedOpportunities()
      } catch (err) {
        console.log(err)
      }
    },
    async deleteOpportunities() {
      try {
        await opportunity.deleteOpportunities({
          opportunities: this.suspendedSelected
        })
        this.getSuspendedOpportunities()
      } catch (err) {
        console.log(err)
      }
    },
    onNeedClick(item) {
      item.start = this.formatDate(item.start)
      item.end = this.formatDate(item.end)
      this.$store.dispatch('opportunity', item)
      this.$router.push(`opportunity-detail/${ item.id }`)
    },
    openDatesModal(item) {
      this.editedDate = []
      this.editedDate = {...item}
      this.openEditDateDialog = true
    },
    //USE TO FORMAT DATE FROM mm-dd-yy to yyyy-mm-dd format
    formatDate(date) {
      let dateArr = date.split('-')
      return '20' + dateArr[2] + '-' + dateArr[0] + '-' + dateArr[1]
    }
  }
}
</script>

<style lang='scss'>
.vue__time-picker input.display-time {
  border: 2px solid #a7a7a7 !important;
  width: 10em !important;
  height: 2.4em !important;
  padding: 0.3em 0.5em !important;
  font-size: 1.2em !important;
  border-radius: 5px !important;
  &:focus-visible {
    outline-color: rgb(92 140 215);
  }
}
</style>
